import * as React from 'react';
import { useState } from "react"
import { List, Link } from '@mui/material';
import { ListItemButton, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import styled from '@emotion/styled';
import Icons from './Icons';

const CustomListItem = styled(ListItemButton)(() => ({
    '&:hover': {
        backgroundColor: '#111'
    }
}));

const CustomListItemIcon = styled(ListItemIcon)(() => ({
    'color': 'white',
    'min-width': '36px'
}));


export default function SidebarItem({ item, sidebarIsOpen, leftWidth, level }) {
    const [open, setOpen] = useState(false)

    if (item.childNavigations != null && item.childNavigations.length > 0) {
        return (
            <>
                <CustomListItem sx={{ width: leftWidth, pl: level }} onClick={() => setOpen(!open)}  >
                    <CustomListItemIcon>
                        <Icons icon={item.icon} />
                        {!sidebarIsOpen && <>
                            {open ? <Icons icon={"KeyboardArrowRightIcon"} /> : <Icons icon={"ExpandMore"} />}
                        </>}
                    </CustomListItemIcon>
                    <ListItemText primary={item.name} sx={{ display: !sidebarIsOpen ? 'none' : 'block' }} />
                    {sidebarIsOpen && <>
                        {open ? <Icons icon={"ExpandLess"} /> : <Icons icon={"ExpandMore"} />}
                    </>}
                </CustomListItem>
                <Collapse in={open} timeout="auto" unmountOnExit   >
                    <List component="div" sx={{ backgroundColor: "rgba(255, 255, 255, .1)", transition: "background-color .15s" }}>
                        {item.childNavigations.map((child, index) => <SidebarItem key={index} item={child} sidebarIsOpen={sidebarIsOpen} leftWidth={leftWidth} level={level + 1} />)}
                    </List>
                </Collapse>
            </>
        )
    } else {
        return (
            <CustomListItem sx={{ width: leftWidth, pl: level }} component={Link} to={`${item.route == null ? '' : item.route}`} >
                <CustomListItemIcon>
                    <Icons icon={item.icon} />
                </CustomListItemIcon>
                <ListItemText sx={{ display: !sidebarIsOpen ? 'none' : 'block' }} primary={item.name} />
            </CustomListItem>
        )
    }
}