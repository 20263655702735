import React, { useContext, useEffect, useState } from "react";
import {
    Box,
    Paper,
    TextField,
    MenuItem,
    Autocomplete,
    ListItemIcon,
    Typography,
    Divider,
    MenuList,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
    resetSidebar,
} from "../../../../globalSlice";
import { useDispatch, useSelector } from "react-redux";




import Check from "@mui/icons-material/Check";
// import DebitVoucher from "./DebitVoucher";
import { AccountPayableContext } from "./context/AccountPayableContextProvide";
import CircularLoader from "../../../../Utlity/loaderBuffer/CircularLoader";
import useSendRequest from "../../../../constants/customeHelper/useSendRequest";
import FaPath from "../../path/FaPath";


const commonStyles = {
    overflow: "auto",
    "&::-webkit-scrollbar": {
        display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
};

const theme = createTheme();

theme.typography.overline = {
    padding: "1",
    fontSize: "0.8rem",
    // color: "#637381",
    fontWeight: "light",
};
theme.typography.h6 = {
    padding: "1",
    fontSize: "0.8rem",
    // color: "#637381",
    fontWeight: "light",
};

const Create = () => {

    const dispatch = useDispatch();
    const leftWidth = useSelector((state) => state.global.leftWidth);
    const { accountPayableModal, model, setModel } = useContext(AccountPayableContext)

    const {
        sendPostRequest,
        sendPutRequest,
        sendDeleteRequest,
        response,
        loading,
        sendCustomGetRequest
    } = useSendRequest(FaPath.accountPayable.accountType)
    const [suppilerCode, setSuppilerCode] = useState([])
    const [supplierCode, setSupplierCode] = useState("Supplier Code")
    useEffect(() => {
        dispatch(resetSidebar());
    }, []);



    function createData(name, vatAmount, invoiceAmount) {
        return { name, vatAmount, invoiceAmount };
    }

    const rows = [
        createData("Total Challan", "0.00", "0.00"),
        createData("Total Bill", 0.0, 0.0),
        createData("Total Accessories", 0.0, 0.0),
        createData("Total Wages", 0.0, 0.0),
        createData("Advance Issue", 0.0, 0.0),
        createData("Grand Total", 0.0, 0.0),
        createData("Deduct Adv. VAT", 0.0, 0.0),
        createData("VAT return", 0.0, 0.0),
        createData("Payable", "", 0.0),
    ];


    const handelAccountType = (e) => {
        let accountType = e.target.value
        setModel()
        setSupplierCode("Supplier Code")
        sendCustomGetRequest(FaPath.accountPayable.supplierCode + accountType)
            .then((valu) => {
                setSuppilerCode(valu)
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    const handelSupplierCode = (event, value) => {

        let code = value && value.code
        value && setSupplierCode(value.name)
        var url="S1914/2023004055";
        // sendCustomGetRequest(FaPath.accountPayable.adjustment + `${code}/new`)
        sendCustomGetRequest(FaPath.accountPayable.adjustment + url)
            .then((valu) => {
                setModel(valu.model)
                console.log('modal view', valu.model)
            })
            .catch((err) => {
                console.log(err.message);
            });

    }




    return (


        <>
            {loading && <CircularLoader />}
            <Box flexGrow={1} padding={0}>
                <Box component={Paper} height={window.innerHeight - 60} padding={1}>
                    {/* <Box
                padding={1}
                borderBottom={1}
                borderColor={"#ddd"}
                display={"flex"}
              >
                <Box flexGrow={1} />

                <Box paddingTop={1}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<Icons icon="SaveIcon" />}
                  >
                    Save
                  </Button>
                </Box>
              </Box> */}
                    <Box padding={0} display={"flex"}>
                        <Box
                            padding={0}
                            borderRight={1}
                            borderColor={"#ddd"}
                            sx={{ width: 250 }}
                            height={window.innerHeight - 80}
                        >
                            <h5>Bill & Invoice</h5>
                            <Divider />
                            {/* <Paper sx={{ width: 249 }}> */}
                            <MenuList dense>
                                <MenuItem sx={{ boxShadow: 0 }} onClick={accountPayableModal.openChallanDetails}>
                                    Challan Details
                                    <ListItemIcon>
                                        <Check />
                                    </ListItemIcon>
                                </MenuItem>
                                <MenuItem onClick={accountPayableModal.openBillinfo}>
                                    Bill Information
                                    <ListItemIcon>
                                        <Check />
                                    </ListItemIcon>
                                </MenuItem>
                                <MenuItem onClick={accountPayableModal.accessoriesInfo}>
                                    Accessories & Stationary Details
                                    <ListItemIcon>
                                        <Check />
                                    </ListItemIcon>
                                </MenuItem>
                                <MenuItem>
                                    Service Charge & Wages Details
                                    <ListItemIcon>
                                        <Check />
                                    </ListItemIcon>
                                </MenuItem>
                                <MenuItem>
                                    Advance Issue
                                    <ListItemIcon>
                                        <Check />
                                    </ListItemIcon>
                                </MenuItem>
                            </MenuList>
                            {/* </Paper> */}
                            <h5>Adjustment</h5>
                            <Divider />
                            {/* <Paper sx={{ width: 249 }}> */}
                            <MenuList dense>
                                <MenuItem>
                                    Challan Details
                                    <ListItemIcon>
                                        <Check />
                                    </ListItemIcon>
                                </MenuItem>
                                <MenuItem>
                                    Bill Information
                                    <ListItemIcon>
                                        <Check />
                                    </ListItemIcon>
                                </MenuItem>
                                <MenuItem>
                                    Accessories & Stationary Details
                                    <ListItemIcon>
                                        <Check />
                                    </ListItemIcon>
                                </MenuItem>
                                <MenuItem>
                                    Service Charge & Wages Details
                                    <ListItemIcon>
                                        <Check />
                                    </ListItemIcon>
                                </MenuItem>
                                <MenuItem>
                                    Advance Issue
                                    <ListItemIcon>
                                        <Check />
                                    </ListItemIcon>
                                </MenuItem>
                                <MenuItem>
                                    Advance Issue
                                    <ListItemIcon>
                                        <Check />
                                    </ListItemIcon>
                                </MenuItem>
                                <MenuItem>
                                    Advance Issue
                                    <ListItemIcon>
                                        <Check />
                                    </ListItemIcon>
                                </MenuItem>
                                <MenuItem>
                                    Advance Issue
                                    <ListItemIcon>
                                        <Check />
                                    </ListItemIcon>
                                </MenuItem>
                            </MenuList>
                            {/* </Paper> */}
                            <h5>Payment</h5>
                            <Divider />
                            {/* <Paper sx={{ width: 249 }}> */}
                            <MenuList dense>
                                <MenuItem>
                                    Challan Details
                                    <ListItemIcon>
                                        <Check />
                                    </ListItemIcon>
                                </MenuItem>
                            </MenuList>
                            {/* </Paper> */}
                        </Box>
                        <Box>
                            <Box
                                padding={0}
                                display={"flex"}
                                width={window.innerWidth - 380 - leftWidth}
                            >
                                <Box width={250} margin={2}>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Account Type"
                                        size="small"
                                        fullWidth
                                        onChange={handelAccountType}
                                    >
                                        {response && response.map((option, index) => (
                                            <MenuItem key={index} value={option.code}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                                <Box width={10}></Box>
                                <Box width={400} margin={2}>
                                    {!loading &&
                                        <Autocomplete
                                            onChange={handelSupplierCode}
                                            size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={suppilerCode}
                                            sx={{ width: 300 }}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => (
                                                <TextField {...params} label={supplierCode} />
                                            )}
                                        />
                                    }
                                </Box>
                            </Box>

                            {/* <DebitVoucher /> */}
                        </Box>
                        <Box
                            padding={0}
                            // display={"flex"}
                            borderLeft={1}
                            borderColor={"#ddd"}
                            sx={{ width: 300 }}
                        >
                            <TableContainer sx={{ ...commonStyles }}>
                                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                sx={{ borderRight: 1, borderColor: "#ddd" }}
                                            ></TableCell>
                                            <TableCell
                                                align="right"
                                                sx={{
                                                    padding: 0.2,
                                                    borderRight: 1,
                                                    borderColor: "#ddd",
                                                }}
                                            >
                                                VAT Amount
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={{
                                                    padding: 0.2,
                                                    borderRight: 1,
                                                    borderColor: "#ddd",
                                                }}
                                            >
                                                Invoice Amount
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                        padding: 0.2,
                                                        borderRight: 1,
                                                        borderColor: "#ddd",
                                                    }}
                                                >
                                                    {row.name}
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    sx={{
                                                        padding: 0.2,
                                                        borderRight: 1,
                                                        borderColor: "#ddd",
                                                    }}
                                                >
                                                    {row.vatAmount}
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    sx={{
                                                        padding: 0.2,
                                                        borderRight: 1,
                                                        borderColor: "#ddd",
                                                    }}
                                                >
                                                    {row.invoiceAmount}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Divider />
                            <ThemeProvider theme={theme}>
                                <Box display="flex" flexDirection="row">
                                    <Box
                                        width="60%"
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        paddingLeft="5px"
                                    >
                                        <Typography variant="overline">
                                            Credit Adjust
                                        </Typography>
                                    </Box>
                                    <Box
                                        width="40%"
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="right"
                                        alignItems="right"
                                        paddingRight="5px"
                                    >
                                        <Typography variant="overline">0.00</Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="row">
                                    <Box
                                        width="60%"
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        paddingLeft="5px"
                                    >
                                        <Typography variant="overline">Other Credit</Typography>
                                    </Box>
                                    <Box
                                        width="40%"
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="right"
                                        alignItems="right"
                                        paddingRight="5px"
                                    >
                                        <Typography variant="overline">0.00</Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="row">
                                    <Box
                                        width="60%"
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        paddingLeft="5px"
                                    >
                                        <Typography variant="overline">Bill Return</Typography>
                                    </Box>
                                    <Box
                                        width="40%"
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="right"
                                        alignItems="right"
                                        paddingRight="5px"
                                    >
                                        <Typography variant="overline">0.00</Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="row">
                                    <Box
                                        width="60%"
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        paddingLeft="5px"
                                    >
                                        <Typography variant="h6">Actual Payable</Typography>
                                    </Box>
                                    <Box
                                        width="40%"
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="right"
                                        alignItems="right"
                                        paddingRight="5px"
                                    >
                                        <Typography variant="h6">0.00</Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="row">
                                    <Box
                                        width="60%"
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        paddingLeft="5px"
                                    >
                                        <Typography variant="overline">
                                            Tax Perc. & Amt.
                                        </Typography>
                                    </Box>
                                    <Box
                                        width="40%"
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="right"
                                        alignItems="right"
                                        paddingRight="5px"
                                    >
                                        <Typography variant="overline">0.00</Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="row">
                                    <Box
                                        width="60%"
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        paddingLeft="5px"
                                    >
                                        <Typography variant="h6">Gross Payment</Typography>
                                    </Box>
                                    <Box
                                        width="40%"
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="right"
                                        alignItems="right"
                                        paddingRight="5px"
                                    >
                                        <Typography variant="h6">0.00</Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="row">
                                    <Box
                                        width="60%"
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        paddingLeft="5px"
                                    >
                                        <Typography variant="overline">
                                            Deposit Adjustment
                                        </Typography>
                                    </Box>
                                    <Box
                                        width="40%"
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="right"
                                        alignItems="right"
                                        paddingRight="5px"
                                    >
                                        <Typography variant="overline">0.00</Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="row">
                                    <Box
                                        width="60%"
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        paddingLeft="5px"
                                    >
                                        <Typography variant="overline">
                                            Loan Adjustment
                                        </Typography>
                                    </Box>
                                    <Box
                                        width="40%"
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="right"
                                        alignItems="right"
                                        paddingRight="5px"
                                    >
                                        <Typography variant="overline">0.00</Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="row">
                                    <Box
                                        width="60%"
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        paddingLeft="5px"
                                    >
                                        <Typography variant="overline">
                                            Other Deduction
                                        </Typography>
                                    </Box>
                                    <Box
                                        width="40%"
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="right"
                                        alignItems="right"
                                        paddingRight="5px"
                                    >
                                        <Typography variant="overline">0.00</Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="row">
                                    <Box
                                        width="60%"
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        paddingLeft="5px"
                                    >
                                        <Typography variant="overline">
                                            Advance Settlement
                                        </Typography>
                                    </Box>
                                    <Box
                                        width="40%"
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="right"
                                        alignItems="right"
                                        paddingRight="5px"
                                    >
                                        <Typography variant="overline">0.00</Typography>
                                    </Box>
                                </Box>

                                <Box display="flex" flexDirection="row">
                                    <Box
                                        width="60%"
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        paddingLeft="5px"
                                    >
                                        <Typography variant="overline">
                                            Manual VAT Adj.
                                        </Typography>
                                    </Box>
                                    <Box
                                        width="40%"
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="right"
                                        alignItems="right"
                                        paddingRight="5px"
                                    >
                                        <Typography variant="overline">0.00</Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="row">
                                    <Box
                                        width="60%"
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        paddingLeft="5px"
                                    >
                                        <Typography variant="overline">
                                            Manual Tax Adj.
                                        </Typography>
                                    </Box>
                                    <Box
                                        width="40%"
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="right"
                                        alignItems="right"
                                        paddingRight="5px"
                                    >
                                        <Typography variant="overline">0.00</Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="row">
                                    <Box
                                        width="60%"
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        paddingLeft="5px"
                                    >
                                        <Typography variant="h6">Voucher Amount</Typography>
                                    </Box>
                                    <Box
                                        width="40%"
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="right"
                                        alignItems="right"
                                        paddingRight="5px"
                                    >
                                        <Typography variant="h6">0.00</Typography>
                                    </Box>
                                </Box>
                            </ThemeProvider>
                        </Box>
                    </Box>
                </Box>
            </Box>

        </>

    );
};

export { Create };
