
class FaPath {
    static accountPayable = {
        queue: 'fa/AccountPayable/Queue',
        accountType: 'fa/StackHolderType/SupplierProducer',
        supplierCode: 'fa/StackHolder/ByTypeCode/0/', //code accountType
        adjustment: 'fa/AccountPayable/byAdjustmentNo/', //S0008/new
    }

    static company = {
        all: 'fa/company',
        add: 'fa/company',
        edit: 'fa/company',
        delete: 'fa/company/' //user id
    }

    static branch = {
        all: 'fa/branch',
        add: 'fa/branch',
        edit: 'fa/branch',
        delete: 'fa/branch/' //user id
    }

}

export default FaPath