import React, { useContext } from 'react'
import CustomModal from '../../../../../Utlity/modal/CustomModal'
import { AccountPayableContext } from '../context/AccountPayableContextProvide'
import DataTable from '../../../../../Utlity/dataTable/DataTable'
import { Box, Button } from '@mui/material'

function ChallanDetails() {
    const { challanShow, setChallanShow } = useContext(AccountPayableContext)
    const userTableCol = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'loginId', headerName: 'login Id', width: 200 },
        { field: 'name', headerName: 'name', width: 300, editable: true },
        { field: 'createdAt', headerName: 'Time', width: 300 },
        // { field: 'name', headerName: 'name', width: 300 },


    ]
    return (
        <>
            <CustomModal state={challanShow} setState={setChallanShow} >
                <DataTable
                    column={userTableCol}
                    data={[]}
                    title={'Challan Details'}
                // loader={loading}
                // action={{
                //     delete: handelDeleteConfirm,
                //     edit: handelEdit
                // }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="outlined" sx={{ marginRight: 1 }}>Cencel</Button>
                    <Button variant="contained" >OK</Button>
                </Box>

            </CustomModal>
        </>
    )
}

export default ChallanDetails