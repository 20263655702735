import React from "react";
import { Box } from "@mui/material";


const Desktop = () => {

    return (
        <Box display={'flex'} >
            Home page
        </Box>
    );
}

export { Desktop };
