import React from "react";
import { Box, SwipeableDrawer } from "@mui/material";
import LeftSidebar from "./LeftSidebar";

const LeftSidebarMobile = ({ state, setState }) => {

    const toggleDrawer = (value) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState(value);
    };

    return (
        <SwipeableDrawer
            anchor="left"
            open={state}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
        >
            <Box
                role="presentation"
                // onClick={toggleDrawer(false)}
                // onKeyDown={toggleDrawer(false)}
                style={{ backgroundColor: '#000', color: 'white', width: '250px', height: "100vh" }}
            >
                <LeftSidebar />
            </Box>
        </SwipeableDrawer>
    );
};

export default LeftSidebarMobile;
