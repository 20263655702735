"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var aafColor = {
  50: '#e0e1e2',
  100: '#b3b3b6',
  200: '#808185',
  300: '#4d4e54',
  400: '#262830',
  500: '#00020b',
  600: '#00020a',
  700: '#000108',
  800: '#000106',
  900: '#000103',
  A100: '#a6a6a6',
  A200: '#8c8c8c',
  A400: '#737373',
  A700: '#666666',
};
var _default = aafColor;
exports.default = _default;

// https://material.io/design/color/the-color-system.html#tools-for-picking-colors