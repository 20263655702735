import * as React from 'react';
import { Box, Button, TextField, Typography, Link, Toolbar, Tooltip, IconButton, FormControlLabel, Radio, RadioGroup, FormLabel, Checkbox, Grid, CircularProgress } from "@mui/material";
import Icons from '../Master/Icons';
import { useForm } from "react-hook-form";
import axios from 'axios';
import useSendRequest from '../../constants/customeHelper/useSendRequest';
import urlPath from '../../constants/path/urlPath';
import { useSelector } from 'react-redux';


const UserProfile = ({ setState, state, editData, action }) => {


    const { register, handleSubmit, watch, setValue, reset, formState: { errors } } = useForm();
    let loaderStatus = useSelector((state) => state.global.loadingSataus);

    React.useEffect(() => {
        if (editData == null) {
            reset()
            console.log('add')
        } else {
            reset()
            setValue("name", editData && editData.name)
            setValue("loginId", editData && editData.loginId)
            // setValue("active", editData && editData.active)

        }

    }, [editData])

    const onSubmit = data => {
        editData == null ? handelSubmitUser(data) : handelEditUser(data)
    }

    const closeForm = () => {

    }

    // user add
    const handelSubmitUser = async (data) => {
        let fromData = {
            ...data,
            active: data.active === "true" ? true : false
        }
        await action.add(urlPath.user.add, fromData)
            .then(valu => {
                !loaderStatus && setState(false)
                reset()
                console.log('form submited', valu)
            }).catch(err => {
                console.log('form error', err)
            })

    }

    //edit user
    const handelEditUser = async (data) => {
        let fromData = {
            ...data,
            id: editData && editData.id,
            active: data.active === "true" ? true : false
        }
        await action.edit(urlPath.user.edit, fromData)
            .then(valu => {
                setState(false)
                reset()
                console.log('form submited', valu)
            }).catch(err => {
                console.log('form error', err)
            })

    }

    return (
        <>
            <Box sx={{ p: 2 }}>

                <Box flexGrow={1} height="20px"></Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <input defaultValue="test" {...register("example")} />
                    <input type="email" name="email" {...register('email')} /> */}
                    <FormLabel id="demo-radio-buttons-group-label">ID/PIN</FormLabel>
                    <TextField
                        // defaultValue={oldname}
                        // label="ID/PIN"
                        name="loginId"
                        variant="outlined" {...register('loginId', { required: true })}
                        helperText={errors.loginId ? "This fild is required !" : ""}
                        error={errors.loginId ? true : false}
                        fullWidth

                    />
                    <Box height="20px"></Box>
                    <FormLabel id="demo-radio-buttons-group-label">User Name</FormLabel>
                    <TextField

                        // label="User Name"
                        variant="outlined"
                        name="name"  {...register('name', { required: true })}
                        helperText={errors.name ? "This fild is required !" : ""}
                        error={errors.name ? true : false}
                        fullWidth
                    />
                    <Box height="20px"></Box>


                    <Grid container>
                        <Grid item xs={6}>
                            <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
                            <Checkbox
                                defaultChecked={editData && editData.active || true}
                                name='active'
                                value="true"
                                {...register('active')}
                                // onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {loaderStatus ?
                                <CircularProgress />
                                :

                                <Tooltip title="Save">
                                    <Button type="submit" variant="contained" color="primary" >
                                        <Icons icon='SaveIcon' />
                                        Save
                                    </Button>
                                </Tooltip>
                            }
                        </Grid>
                    </Grid>

                    {/* <TextField
                        label="Section"
                        variant="outlined"
                        name="section"  {...register('section', { required: true })}
                        helperText={errors.section ? "This fild is required !" : ""}
                        error={errors.section ? true : false}
                        fullWidth
                    />
                    <Box height="20px"></Box>

                    <TextField
                        label="Designation"
                        variant="outlined"
                        name="designation"  {...register('designation', { required: true })}
                        helperText={errors.designation ? "This fild is required !" : ""}
                        error={errors.designation ? true : false}
                        fullWidth
                    />
                    <Box height="20px"></Box>

                    <TextField
                        label="Password"
                        type={"password"}
                        variant="outlined"
                        name="password"  {...register('password', { required: true })}
                        helperText={errors.password ? "This fild is required !" : ""}
                        error={errors.password ? true : false}
                        fullWidth />
                    <Box flexGrow={1} height="20px"></Box>

                    <TextField
                        label="Confirm password"
                        type={"password"}
                        variant="outlined"
                        name="cPassword"  {...register('cPassword', {
                            required: true,
                            validate: value =>
                                value === watch("password") || "The passwords do not match"
                        })}
                        helperText={errors.cPassword ? errors.cPassword.message : ""}
                        error={errors.cPassword ? true : false}
                        fullWidth />
                    <Box flexGrow={1} height="20px"></Box> */}

                    <Box flexGrow={1} />

                </form>

            </Box>
        </>
    );
}

export { UserProfile };
