
class FaPath {
    static accountPayable = {
        queue: 'fa/AccountPayable/Queue',
        accountType: 'fa/StackHolderType/SupplierProducer',
        supplierCode: 'fa/StackHolder/ByTypeCode/0/', //code accountType
        adjustment: 'fa/AccountPayable/byAdjustmentNo/', //S0008/new
    }



}

export default FaPath