import React from 'react'
import { Fab } from '@mui/material'
import Icons from '../../components/Master/Icons'

function FloatingAddButton({ action }) {
    return (
        <Fab color="primary" aria-label="add" style={{ position: "fixed", bottom: 75, right: 40 }} onClick={action} >
            <Icons icon='AddIcon' />
        </Fab>
    )
}

export default FloatingAddButton