import * as React from 'react';
import { Box, Button, TextField, Typography, Link, Toolbar, Tooltip, IconButton, FormControlLabel, Radio, RadioGroup, FormLabel, Checkbox, Grid, CircularProgress, Select, MenuItem, FormHelperText, FormControl, InputLabel } from "@mui/material";
import Icons from '../Master/Icons';
import { useForm } from "react-hook-form";
import axios from 'axios';
import useSendRequest from '../../constants/customeHelper/useSendRequest';
import urlPath from '../../constants/path/urlPath';
import { useSelector } from 'react-redux';


const InputFild = ({ setState, state, editData = null, action }) => {



    const {
        response,
        loading
    } = useSendRequest(urlPath.api.actions)

    const { register, handleSubmit, watch, setValue, reset, formState: { errors } } = useForm({
        defaultValues: {
            httpActionId: null,

        }
    });
    let loaderStatus = useSelector((state) => state.global.loadingSataus);



    React.useEffect(() => {
        // console.log('edit data', kk)
        if (editData == null) {
            reset()
            console.log('add')
        } else {
            reset()
            setValue("name", editData && editData.name)
            setValue("url", editData && editData.url)
            setValue("httpActionId", editData && editData.httpActionId)
        }

    }, [editData])

    const onSubmit = data => {
        editData == null ? handelSubmitUser(data) : handelEditUser(data)
    }

    const closeForm = () => {

    }

    // user add
    const handelSubmitUser = async (data) => {



        await action.add(urlPath.api.add, data)
            .then(valu => {
                !loaderStatus && setState(false)
                reset()
                console.log('form submited', valu)
            }).catch(err => {
                console.log('form error', err)
            })

    }

    //edit user
    const handelEditUser = async (data) => {
        let fromData = {
            ...data,
            id: editData && editData.id,
            active: data.active === "true" ? true : false
        }
        await action.edit(urlPath.user.edit, fromData)
            .then(valu => {
                setState(false)
                reset()
                console.log('form submited', valu)
            }).catch(err => {
                console.log('form error', err)
            })

    }

    return (
        <>
            <Box style={{ padding: '0px 300px 30px 300px' }}  >

                <Box flexGrow={1} height="20px"></Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <input defaultValue="test" {...register("example")} />
                    <input type="email" name="email" {...register('email')} /> */}
                    <FormLabel id="demo-radio-buttons-group-label">ID/PIN</FormLabel>
                    <TextField
                        // defaultValue={oldname}
                        // label="ID/PIN"
                        name="name"
                        variant="outlined" {...register('name', { required: true })}
                        helperText={errors.loginId ? "This fild is required !" : ""}
                        error={errors.loginId ? true : false}
                        fullWidth

                    />
                    <Box height="20px"></Box>
                    <FormLabel id="demo-radio-buttons-group-label">User Name</FormLabel>
                    <TextField

                        // label="User Name"
                        variant="outlined"
                        name="url"  {...register('url', { required: true })}
                        helperText={errors.name ? "This fild is required !" : ""}
                        error={errors.name ? true : false}
                        fullWidth
                    />
                    <Box height="20px"></Box>


                    <Grid container>
                        <Grid item xs={6}>
                            <FormLabel id="demo-radio-buttons-group-label">http Action</FormLabel>

                            {editData &&
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    variant="outlined"
                                    name="httpActionId"  {...register('httpActionId', { required: true })}
                                    fullWidth
                                    defaultValue={editData.httpActionId}
                                >
                                    {response && response.map((option, index) => (
                                        <MenuItem key={index} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            }

                            {editData == null &&
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    variant="outlined"
                                    name="httpActionId"  {...register('httpActionId', { required: true })}
                                    fullWidth
                                >
                                    {response && response.map((option, index) => (
                                        <MenuItem key={index} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            }


                            {/* <FormControl sx={{ minWidth: 300 }}>
                                <FormLabel id="demo-radio-buttons-group-label">http Action</FormLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    name="httpActionId"
                                    value={2}
                                    error={errors.httpActionId ? true : false}
                                    {...register('httpActionId', { required: true })}

                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {response && response.map((item, index) =>
                                        <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                    )}

                                </Select>
                                <FormHelperText sx={{ color: 'red' }}>{errors.httpActionId ? "This fild is required !" : ""}</FormHelperText>
                            </FormControl> */}
                        </Grid>

                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {loaderStatus ?
                                <CircularProgress />
                                :

                                <Tooltip title="Save">
                                    <Button type="submit" variant="contained" color="primary" >
                                        <Icons icon='SaveIcon' />
                                        Save
                                    </Button>
                                </Tooltip>
                            }
                        </Grid>
                    </Grid>

                    {/* <TextField
                        label="Section"
                        variant="outlined"
                        name="section"  {...register('section', { required: true })}
                        helperText={errors.section ? "This fild is required !" : ""}
                        error={errors.section ? true : false}
                        fullWidth
                    />
                    <Box height="20px"></Box>

                    <TextField
                        label="Designation"
                        variant="outlined"
                        name="designation"  {...register('designation', { required: true })}
                        helperText={errors.designation ? "This fild is required !" : ""}
                        error={errors.designation ? true : false}
                        fullWidth
                    />
                    <Box height="20px"></Box>

                    <TextField
                        label="Password"
                        type={"password"}
                        variant="outlined"
                        name="password"  {...register('password', { required: true })}
                        helperText={errors.password ? "This fild is required !" : ""}
                        error={errors.password ? true : false}
                        fullWidth />
                    <Box flexGrow={1} height="20px"></Box>

                    <TextField
                        label="Confirm password"
                        type={"password"}
                        variant="outlined"
                        name="cPassword"  {...register('cPassword', {
                            required: true,
                            validate: value =>
                                value === watch("password") || "The passwords do not match"
                        })}
                        helperText={errors.cPassword ? errors.cPassword.message : ""}
                        error={errors.cPassword ? true : false}
                        fullWidth />
                    <Box flexGrow={1} height="20px"></Box> */}

                    <Box flexGrow={1} />

                </form>

            </Box>
        </>
    );
}

export { InputFild };
