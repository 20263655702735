import React from "react";
import { Box } from "@mui/material";
import NavbarMobile from "./NavbarMobile";

const Mobile = (props) => {
    return (
        <Box style={{ height: "100vh" }} >
            <NavbarMobile />
            {props.children}
        </Box>
    );
}

export { Mobile };
