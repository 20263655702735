import React from "react";
import { useState } from "react"
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse, Link } from '@mui/material';
import styled from '@emotion/styled';
import Icons from './Icons';
import { useNavigate } from "react-router-dom";


const CustomListItem = styled(ListItemButton)(() => ({
    '&:hover': {
        backgroundColor: '#111'
    }
}));

const CustomListItemIcon = styled(ListItemIcon)(() => ({
    'color': 'white',
    'min-width': '36px'
}));


export default function SidebarItemRight({ item, leftWidth, level }) {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false)

    if (item.childNavigations != null && item.childNavigations.length > 0) {
        return (
            <>
                <CustomListItem sx={{ width: leftWidth, pl: level }} onClick={() => setOpen(!open)}  >
                    <CustomListItemIcon>
                        <Icons icon={item.icon} />
                    </CustomListItemIcon>
                    <ListItemText primary={item.name} />
                    {open ? <Icons icon={"ExpandLess"} /> : <Icons icon={"ExpandMore"} />}
                </CustomListItem>
                <Collapse in={open} timeout="auto" unmountOnExit   >
                    <List component="div" sx={{ backgroundColor: "rgba(255, 255, 255, .1)", transition: "background-color .15s" }}>
                        {item.childNavigations.map((child, index) => <SidebarItemRight key={index} item={child} leftWidth={leftWidth} level={level + 1} />)}
                    </List>
                </Collapse>
            </>
        )
    } else {
        return (
            <CustomListItem sx={{ width: leftWidth, pl: level }} onClick={() => navigate(item.route)} >
                {/* <CustomListItem sx={{ width: leftWidth, pl: level }} component={Link} to={`${item.route}`} > */}
                <CustomListItemIcon>
                    <Icons icon={item.icon} />
                </CustomListItemIcon>
                <ListItemText primary={item.name} />
            </CustomListItem>
        )
    }
}