import React, { useContext, useEffect, useState } from 'react'
import CustomModal from '../../../../../Utlity/modal/CustomModal'
import { AccountPayableContext } from '../context/AccountPayableContextProvide'
import DataTable from '../../../../../Utlity/dataTable/DataTable'
import { Autocomplete, Box, Button, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';


/**
 * drop down selct fild component
 */


// const [personName, setPersonName] = React.useState([]);

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };

// const names = [
//     'Oliver Hansen',
//     'Van Henry',
//     'April Tucker',
//     'Ralph Hubbard',
//     'Omar Alexander',
//     'Carlos Abbott',
//     'Miriam Wagner',
//     'Bradley Wilkerson',
//     'Virginia Andrews',
//     'Kelly Snyder',
// ];
// const handleChange = (event, valu) => {
//     console.log("on Chnage event--", event.target.value)
//     const {
//         target: { value },
//     } = event;
//     setPersonName(value);
// };

// return (
//     <FormControl sx={{ m: 1, minWidth: 110, p: 0 }} size="small">
//         <Select
//             displayEmpty
//             value={personName}
//             onChange={handleChange}
//             id="demo-simple-select-standard"
//         // input={<OutlinedInput />}
//         >
//             <MenuItem disabled value="">
//                 <p>{label}</p>
//             </MenuItem>
//             {data && data.map((item, index) => (
//                 <MenuItem key={index} value={item.code} {...item}>
//                     {item.name}
//                 </MenuItem>
//             ))}
//         </Select>
//     </FormControl>
// )

export const SelectFild = ({ modelList, modelData, handelChange, index, fieldName }) => {

    // console.log(model);

    // const handelChange = (value) => {
    //     // let code = value && value.code
    //     // console.log(value);
    //     modelData=value;
    // }

    return (

        <Autocomplete
            variant="outlined"
            onChange={(event,value) => handelChange(index, fieldName, value)}
            size="small"
            disablePortal
            id="combo-box-demo"
            defaultValue={modelData}
            options={modelList}
            sx={{
                width: 200, marginRight: 0.5, "& input::label": {
                    fontSize: 14,
                    color: 'red'
                },
            }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
                <TextField {...params} />
            )}
        />
    )

}

/**
 * date fild component
 */
export const DatePickerFild = () => {
    return (
        <FormControl sx={{ m: 1, maxWidth: 110, p: 0 }} size="small">
            {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker slotProps={{ textField: { size: 'small' }, fontSize: 10 }} />
            </LocalizationProvider>

        </FormControl>
    )
}

/**
 * text fild component
 */
export const TextFildCom = ({ size = null, label = null, model }) => {
    return (


        <TextField
            defaultValue={model}
            placeholder={label}
            sx={{
                "& input::placeholder": {
                    fontSize: 14,
                    color: 'gray'
                },
                marginRight: 0.5

            }}
            // label={label}
            // id="filled-hidden-label-small"
            size="small"
        />

    )
}


export const CustomeTableRow = ({ data, addFunation }) => {

    const { model, setModel } = useContext(AccountPayableContext)

    const handelAdd = (index) => {
        var f1 = Array();
        var f2 = Array();
        var itemList = model.otherAccessoryList;

        for (var count = 0; count < itemList.length; count++) {
            if (count <= index) {
                f1.push(itemList[count]);
            } else {
                f2.push(itemList[count]);
            }
        }
        var x = {};
        x.chartOfAccount = {};
        x.narration = "";
        f1.push(x);

        f1.push.apply(f1, f2);
        setModel({ ...model, otherAccessoryList: f1 });
        // $scope.VoucherView.Transactions = f1;
    }

    const handelRemove = (index) => {
        var itemList = model.otherAccessoryList;
        itemList.splice(index, 1);
        setModel({ ...model, otherAccessoryList: itemList });
    }

    const handelChange = (index, fieldName, val) => {
        var itemList = model.otherAccessoryList;
        if (fieldName == "chartOfAccount") {
            itemList[index].fieldName = val;
        }
        setModel({ ...model, otherAccessoryList: itemList });
    }

    useEffect(()=>{
            console.log(model.otherAccessoryList);
    },[model]);

    // vatOptionList
    // taxOptionList
    // chartOfAccountList
    // stackHolderCAList
    // departmentList
    // purposeList
    // deliveryChallanList
    // billList
    // otherAccessoryList
    // otherWagesList

    return (
        <>
            {model.otherAccessoryList.map((item, index) =>
                <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, padding: '0px 0px' }}
                >


                    <TableCell sx={tablePadding}>
                        {/* <input type='text' value={item.narration} onChange={(event) =>handelChange(index, event.target.value )} /> */}
                        <SelectFild modelList={model.chartOfAccountList} modelData={item.chartOfAccount} index={index} fieldName={"chartOfAccount"} handelChange={handelChange} />
                    </TableCell>

                    <TableCell sx={tablePadding}>
                        <SelectFild label={"Accounts"} data={model.stackHolderCAList} />
                    </TableCell>

                    <TableCell sx={tablePadding}>
                        <SelectFild label={"Purpose"} data={model.purposeList} />
                    </TableCell>

                    <TableCell sx={tablePadding}>
                        <SelectFild label={"Department"} data={model.departmentList} />
                    </TableCell>

                    <TableCell sx={tablePadding}>
                        <TextFildCom label={"Narration"} size={140} model={item.narration} />
                    </TableCell>

                    <TableCell sx={tablePadding}>
                        <TextFildCom label={"Amount"} />
                    </TableCell>

                    <TableCell sx={tablePadding}>
                        <TextFildCom label={"VAT%"} />
                    </TableCell>

                    <TableCell sx={tablePadding}>
                        <TextFildCom label={"VAT Amt"} />
                    </TableCell>

                    <TableCell sx={tablePadding}>
                        <TextFildCom label={"Tax"} />
                    </TableCell>

                    <TableCell sx={tablePadding}>
                        <TextFildCom label={"Total Amount"} size={140} />
                    </TableCell>

                    <TableCell sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <IconButton aria-label="add" onClick={() => handelAdd(index)} >
                            <AddCircleIcon />
                        </IconButton>
                        <IconButton aria-label="delete" onClick={() => handelRemove(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </TableCell>

                </TableRow>
            )}
        </>
    )
}


const tablePadding = {
    padding: '0px 0px',
}

function AccessoriesStationary() {
    const { accessoriesShow, setAccessoriesShow } = useContext(AccountPayableContext)







    return (
        <>
            <CustomModal state={accessoriesShow} setState={setAccessoriesShow} >

                <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', minHeight: 400, p: 0, width: '100%' }}>

                    <Typography variant="h6" sx={{ mb: 3, ml: 2 }}>Accessories & Stationary Details</Typography>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>Data</TableCell> */}
                                    <TableCell >Account Head</TableCell>
                                    <TableCell >Accounts</TableCell>
                                    <TableCell >Purpose</TableCell>
                                    <TableCell >Department</TableCell>
                                    <TableCell sx={{ width: 140 }}>Narration</TableCell>
                                    <TableCell >Amount</TableCell>
                                    <TableCell >VAT%</TableCell>
                                    <TableCell >VAT Amt</TableCell>
                                    <TableCell >Tax</TableCell>
                                    <TableCell sx={{ width: 140 }}>Total Amount</TableCell>
                                    <TableCell >Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {/* table rows */}
                                <CustomeTableRow />


                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="outlined" sx={{ marginRight: 1 }}>Cencel</Button>
                    <Button variant="contained" >OK</Button>
                </Box>

            </CustomModal>
        </>
    )
}

export default AccessoriesStationary