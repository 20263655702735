import * as React from 'react';
import {
    Box,
    Link,
    List,
    SwipeableDrawer,
} from "@mui/material";

import { useSelector } from 'react-redux';
import SidebarItemRight from "./SidebarItemRight";


const RightSidebar = ({ state, setState }) => {
    const routes = useSelector((state) => state.global.routes);
    const rightMenus = routes.filter((menu) => menu.location === 'Right');

    const toggleDrawer = (value) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState(value);
    };

    return (
        <SwipeableDrawer
            anchor="right"
            open={state}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
        >
            <Box
                role="presentation"
                // onClick={toggleDrawer(false)}
                // onKeyDown={toggleDrawer(false)}
                style={{ backgroundColor: '#000', color: 'white', width: '250px', height: "100vh" }}
            >
                <Box position={"relative"} >
                    <Link href="#" display={"flex"} height={"60px"} alignItems={"center"}
                        justifyContent={"center"} underline="none" color={'white'} fontSize={20} style={{ borderBottom: '1px solid #ccc' }}  >
                        <Box>Setting Menu</Box>
                    </Link>
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        {rightMenus.map((item, index) => <SidebarItemRight key={index} item={item} level={1} />)}

                    </List>
                </Box>
            </Box>
        </SwipeableDrawer>
    );
};

export default RightSidebar;
