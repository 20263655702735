import React from "react";
import useWindowSize from '../../Utlity/useWindowSize';
import { Layout as MasterLayout } from '../Master/Layout';
import { Desktop } from './Desktop';
import { Mobile } from './Mobile';

const Layout = () => {

    return (
        <>
            {/* <Mobile /> */}
            {/* <MasterLayout><Desktop /></MasterLayout> */}
            {useWindowSize().desktop && <MasterLayout><Desktop /></MasterLayout>}
            {useWindowSize().mobile && <MasterLayout><Mobile /></MasterLayout>}
        </>
    );
}

export { Layout };
