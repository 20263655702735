import React, { useEffect, useState } from "react";
import EntryForm from './EntryForm'
import useSendRequest from "../../constants/customeHelper/useSendRequest";
import urlPath, { userApi } from "../../constants/path/urlPath"

import DataTable from "../../Utlity/dataTable/DataTable";
import FloatingAddButton from "../../Utlity/addButton/FloatingAddButton";
import { setConfirmDailog, setDialogMessage } from "../../globalSlice";
import { useDispatch } from "react-redux";



const Desktop = () => {

    const [rightSidebarState, setRightSidebarState] = React.useState(false);
    const [editData, setEditData] = useState(null)
    const dispatch = useDispatch();
    // custome hook
    const {
        sendPostRequest,
        sendPutRequest,
        sendDeleteRequest,
        response,
        loading
    } = useSendRequest(urlPath.user.all)





    //user delete 
    const handelDeleteUser = async (id) => {
        await sendDeleteRequest(urlPath.user.delete + id).then(valu => {
            console.log('delete done')
        }).catch(err => {
            console.log('delete error', err.message)
        })

    }
    /**
     * 
     */
    const handelDeleteConfirm = (id) => {

        dispatch(setConfirmDailog({
            type: 'error',
            message: "Are you sure you want to delete",
            action: () => handelDeleteUser(id)
        }));

    }

    //open edit modal
    const handelEdit = (data) => {
        setRightSidebarState(true)
        setEditData(data)
    }


    //opne add modal 
    const handelAdd = () => {
        setEditData(null)
        setRightSidebarState(true)
    }

    const userTableCol = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'loginId', headerName: 'login Id', width: 200 },
        { field: 'name', headerName: 'name', width: 300, editable: true },
        { field: 'createdAt', headerName: 'Time', width: 300 },
        // { field: 'name', headerName: 'name', width: 300 },


    ]

    return (
        <>


            <DataTable
                column={userTableCol}
                data={response}
                title={'User List'}
                loader={loading}
                action={{
                    delete: handelDeleteConfirm,
                    edit: handelEdit
                }}
            />

            {/* <DataTableEditable
                column={userTableCol}
                data={response}
                action={{
                    delete: handelDeleteConfirm,
                    edit: handelEdit
                }}
            /> */}
            <EntryForm
                state={rightSidebarState}
                setState={setRightSidebarState}
                action={{
                    add: sendPostRequest,
                    edit: sendPutRequest
                }}
                editData={editData}
            />
            <FloatingAddButton action={handelAdd} />
            {/* <AlertDialog
                open={open}
                setOpen={setOpen}
                action={() => handelDeleteUser(selectId)}
            /> */}
        </>
    )


}

export { Desktop };
