import React from "react";
import { Card, Avatar, CardContent, Typography, CardActions, Button, Menu, Box, MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import Icons from './Icons';
import { setLogin } from '../../globalSlice';
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import user from '../../assets/images/anis.png';

export default function Profile({ menuId, anchorEl, profileOpen, handleMenuClose }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const hendleOut = (event) => {
        localStorage.setItem("user", null);
        localStorage.setItem("userProfileTemplate", null);
        dispatch(setLogin(false));
        navigate("/login");
    };

    return (
        <Menu elevation={3}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={profileOpen}
            onClose={handleMenuClose}
        >
            <Card elevation={0} sx={{ maxWidth: 345 }} padding={8}>
                <Box display="flex" justifyContent="center" padding={2} >
                    <Avatar
                        alt="Anisur"
                        src={user}
                        sx={{ width: 100, height: 100 }}
                    />
                </Box>
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                        Md. Anisur Rahman
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Lead Software Engineer
                    </Typography>
                </CardContent>
                {/* <CardActions>
                    <Button size="small">Profile</Button>
                    <Button size="small">Change password</Button>
                </CardActions> */}
                <Divider />
                <MenuItem>
                    <ListItemIcon>
                        <Icons icon="AccountCircle" />
                    </ListItemIcon>
                    <ListItemText>My Profile</ListItemText>
                </MenuItem>
                <MenuItem  onClick={hendleOut}  >
                    <ListItemIcon>
                        <Icons icon="LogoutIcon" />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </MenuItem>
            </Card>
        </Menu>
    );
}