import React, { useEffect, useRef, useState } from "react";
import { DataTableMobile } from "../../Utlity/dataTable/DataTableMobile";
import useSendRequest from "../../constants/customeHelper/useSendRequest";
import urlPath from "../../constants/path/urlPath";
import { useDispatch } from "react-redux";
import { setConfirmDailog } from "../../globalSlice";
import EntryForm from "./EntryForm";
import FloatingAddButton from "../../Utlity/addButton/FloatingAddButton";



const Mobile = () => {
    const [rightSidebarState, setRightSidebarState] = React.useState(false);
    const [editData, setEditData] = useState(null)
    const dispatch = useDispatch();
    // custome hook
    const {
        sendPostRequest,
        sendPutRequest,
        sendDeleteRequest,
        response,
        loading
    } = useSendRequest(urlPath.user.all)





    //user delete 
    const handelDeleteUser = async (id) => {
        await sendDeleteRequest(urlPath.user.delete + id).then(valu => {
            console.log('delete done')
        }).catch(err => {
            console.log('delete error', err.message)
        })

    }
    /**
     * 
     */
    const handelDeleteConfirm = (id) => {

        dispatch(setConfirmDailog({
            type: 'error',
            message: "Are you sure you want to delete",
            action: () => handelDeleteUser(id)
        }));

    }

    //open edit modal
    const handelEdit = (data) => {
        setEditData()


        setRightSidebarState(true)
        setEditData(data)

    }


    //opne add modal 
    const handelAdd = () => {
        setEditData(null)
        setRightSidebarState(true)
    }

    return (
        <>
            <DataTableMobile
                data={response.sort((a, b) => b.id - a.id)}
                loader={loading}
                action={{
                    delete: handelDeleteConfirm,
                    edit: handelEdit
                }}

            />
            <EntryForm
                state={rightSidebarState}
                setState={setRightSidebarState}
                action={{
                    add: sendPostRequest,
                    edit: sendPutRequest
                }}
                editData={editData}
            />

            <FloatingAddButton action={handelAdd} />
        </>
    )


}

export { Mobile };
