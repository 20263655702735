import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography, Link, Toolbar, Tooltip, IconButton, SwipeableDrawer } from "@mui/material";
import Icons from '../components/Master/Icons';

const FormHeader = (props) => {

    const toggleDrawer = (value) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        props.setState(value);
    };
    return (
        <SwipeableDrawer
            anchor="bottom"
            open={props.state}
            // onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            // modal={false}
            PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
        >
            <Box component="div" style={{ backgroundColor: '#fff', color: "#000", borderRadius: '16px 16px 0px 0px' }}  >
                <Toolbar style={{ borderBottom: '1px solid #ccc' }} >
                    <Typography variant="h6" component="div" >
                        {props.title}
                    </Typography>
                    <Box flexGrow={1} />
                    <Tooltip title="Close">
                        <IconButton onClick={toggleDrawer(false)}>
                            <Icons icon='CloseIcon' />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
                {props.children}
            </Box>
        </SwipeableDrawer>
    );
};

export default FormHeader ;
