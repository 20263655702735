import React, { useEffect, useState } from "react";
import useSendRequest from "../../../constants/customeHelper/useSendRequest";
import FaPath from "../path/FaPath"
import DataTable from "../../../Utlity/dataTable/DataTable";
import FloatingAddButton from "../../../Utlity/addButton/FloatingAddButton";
import { setConfirmDailog, setDialogMessage } from "../../../globalSlice";
import { useDispatch } from "react-redux";
import EntryForm from "./EntryForm";

const Desktop = () => {
    const [formTitle, setFormTitle] = useState("");
    const [rightSidebarState, setRightSidebarState] = useState(false);
    const [editData, setEditData] = useState(null)
    const [allData, setAllData] = useState([]);
    const dispatch = useDispatch();
    // custome hook
    const {
        sendPostRequest,
        sendPutRequest,
        sendDeleteRequest,
        response,
        loading
    } = useSendRequest(FaPath.branch.all)


    //user delete 
    const handelDeleteUser = async (id) => {
        await sendDeleteRequest(FaPath.branch.delete + id).then(valu => {
            console.log('delete done')
        }).catch(err => {
            console.log('delete error', err.message)
        });
    }
    /**
     * 
     */
    const handelDeleteConfirm = (id) => {
        dispatch(setConfirmDailog({
            type: 'error',
            message: "Are you sure you want to delete",
            action: () => handelDeleteUser(id)
        }));
    }

    //open edit modal
    const handelEdit = (data) => {
        setFormTitle("Edit Branch")
        setRightSidebarState(true)
        setEditData(data)
    }


    //opne add modal 
    const handelAdd = () => {
        setFormTitle("Add Branch")
        setEditData(null)
        setRightSidebarState(true)
    }

    const userTableCol = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'companyName', headerName: 'Company Name', width: 200, editable: true },
        { field: 'name', headerName: 'Name', width: 200, editable: true },
        { field: 'address', headerName: 'Address', width: 300 },
        { field: 'phone', headerName: 'Phone', width: 100 },
        { field: 'webSide', headerName: 'Webside', width: 200 },
        // { field: 'active', headerName: 'Active', width: 100 },
    ];

    useEffect(() => {
        let results = response.map(task => {
            return { ...task, companyName: task.company.name, active: task.active === "y" ? true : false }
        });
        setAllData(results.sort((a, b) => b.id - a.id));
    }, [response]);

    return (
        <>
            <DataTable
                column={userTableCol}
                data={allData}
                title={'Branch List'}
                loader={loading}
                action={{
                    delete: handelDeleteConfirm,
                    edit: handelEdit
                }}
            />
            <EntryForm
                title={formTitle}
                setState={setRightSidebarState}
                state={rightSidebarState}
                editData={editData}
                action={{
                    add: sendPostRequest,
                    edit: sendPutRequest
                }}
            />
            <FloatingAddButton action={handelAdd} />
            {/* <AlertDialog
                open={open}
                setOpen={setOpen}
                action={() => handelDeleteUser(selectId)}
            /> */}
        </>
    )
}

export { Desktop };
