import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import { Box, CircularProgress, Button, Modal, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { setConfirmDailog, setDialogMessage } from '../../globalSlice';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0px solid #fff',
    boxShadow: 24,
    p: 4,
};


const AlertDailog = () => {

    // const dialogStatus = useSelector((state) => state.global.dialogStatus);
    const dialogMessage = useSelector((state) => state.global.confirmDailog);
    const dispatch = useDispatch();

    const color = dialogMessage.type == 'error' ? 'red' : 'green';
    const isDialogOpen = dialogMessage.message.length > 0 ? true : false;

    const hendleDelete = (event) => {
        dispatch(setConfirmDailog({ status: '', message: '', action: '' }));
        dialogMessage.action()
    };

    const handelCancel = () => {
        dispatch(setConfirmDailog({ status: '', message: '', action: '' }));
    }

    const body = (
        <Box sx={style} display="flex" flexDirection="column" borderRadius={1} >
            <p id="simple-modal-title" style={{ color: `${color}` }} >{dialogMessage.message}</p>
            <Box display="flex" flexDirection="row">



                <Box flexGrow={1}></Box>
                <Button variant="contained" sx={{ mr: 2 }} color="primary" onClick={handelCancel} >NO</Button>
                <Button variant="contained" color="primary" onClick={hendleDelete} >YES</Button>
            </Box>
        </Box>
    );

    return (
        <Dialog
            open={isDialogOpen}
            onClose={handelCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" display={'flex'} sx={{ alignItems: 'center', pt: 3 }}>
                <WarningTwoToneIcon color='error' fontSize='large' sx={{ mr: 2 }} />  {dialogMessage.message}
            </DialogTitle>

            <DialogActions sx={{ pb: 3, pr: 5 }}>
                <Button sx={{ backgroundColor: "chip.error", boxShadow: 1 }} onClick={handelCancel}>NO</Button>
                <Button sx={{ backgroundColor: "chip.success", boxShadow: 1 }} onClick={hendleDelete} autoFocus>
                    YES
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AlertDailog;
