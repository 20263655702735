import React, { useContext, useEffect, useState } from "react";
import EntryForm from "./EntryForm";
import useSendRequest from "../../../../constants/customeHelper/useSendRequest";
import urlPath from "../../../../constants/path/urlPath";


import { setConfirmDailog, setDialogMessage } from "../../../../globalSlice";
import { useDispatch } from "react-redux";
import DataTable from "../../../../Utlity/dataTable/DataTable";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Fab, createTheme } from "@mui/material";


// import DebitVoucher from "./DebitVoucher";
import AccountPayableContextProvide, { AccountPayableContext } from "./context/AccountPayableContextProvide";
import ChallanDetails from "./challanDetails/ChallanDetails";
import { Create } from "./Create";
import BillInformation from "./billInformation/BillInformation";
import AccessoriesStationary from "./accessoriesStationary/AccessoriesStationary";
import FaPath from "../../path/FaPath";


const commonStyles = {
  overflow: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "-ms-overflow-style": "none",
  "scrollbar-width": "none",
};

const theme = createTheme();

theme.typography.overline = {
  padding: "1",
  fontSize: "0.8rem",
  // color: "#637381",
  fontWeight: "light",
};
theme.typography.h6 = {
  padding: "1",
  fontSize: "0.8rem",
  // color: "#637381",
  fontWeight: "light",
};

const Desktop = () => {
  const [rightSidebarState, setRightSidebarState] = React.useState(false);
  const [editData, setEditData] = useState(null);
  const [createNow, setCreateNow] = useState(false);

  const dispatch = useDispatch();

  // custome hook
  const {
    sendPostRequest,
    sendPutRequest,
    sendDeleteRequest,
    response,
    loading,
  } = useSendRequest(FaPath.accountPayable.queue);

  //   console.log(response);

  //user delete
  const handelDeleteUser = async (id) => {
    await sendDeleteRequest(urlPath.user.delete + id)
      .then((valu) => {
        console.log("delete done");
      })
      .catch((err) => {
        console.log("delete error", err.message);
      });
  };
  /**
   *
   */
  const handelDeleteConfirm = (id) => {
    dispatch(
      setConfirmDailog({
        type: "error",
        message: "Are you sure you want to delete",
        action: () => handelDeleteUser(id),
      })
    );
  };

  //open edit modal
  const handelEdit = (data) => {
    setRightSidebarState(true);
    setEditData(data);
  };

  const CreateNew = () => {
    setCreateNow(prev => !prev)
  };


  const userTableCol = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "adjustment_no", headerName: "Adjustment No", width: 150 },
    { field: "account_no", headerName: "Party Code", width: 100 },
    { field: "account_name", headerName: "Party Name", width: 150 },
    { field: "transaction_date", headerName: "Transaction Date", width: 150 },
    { field: "transaction_no_dv", headerName: "Debit Voucher No ", width: 150 },
    {
      field: "transaction_no_jv",
      headerName: "Journal Voucher No",
      width: 100,
    },
    { field: "transaction_no_jv_amount", headerName: "JV Amount", width: 100 },
    { field: "transaction_no_dv_amount", headerName: "DV Amount", width: 100 },
    { field: "creator", headerName: "Creator", width: 50 },
  ];

  return (

    <>


      {createNow == false ? (
        <>
          <Stack
            spacing={2}
            direction="row"
            style={{ position: "fixed", top: 60, right: 40 }}
            sx={{
              margin: "10px",
            }}
          >
            <Button variant="outlined">Send to Verify</Button>
            <Button variant="contained" onClick={CreateNew}>
              Create new
            </Button>
          </Stack>
          <DataTable
            column={userTableCol}
            data={response}
            title={"Accounts Payable"}
            action={{
              delete: handelDeleteConfirm,
              edit: handelEdit,
            }}
          />
          <EntryForm
            state={rightSidebarState}
            setState={setRightSidebarState}
            action={{
              add: sendPostRequest,
              edit: sendPutRequest,
            }}
            editData={editData}
          />
        </>
      ) : (
        <>
          <AccountPayableContextProvide>
            <Create />
            <ChallanDetails />
            <BillInformation />
            <AccessoriesStationary />

          </AccountPayableContextProvide>
        </>
      )}


    </>

  );
};

export { Desktop };
