
class urlPath {
    static user = {
        all: 'auth/user',
        add: 'auth/user',
        edit: 'auth/user',
        delete: 'auth/User/' //user id
    }
    static api = {
        all: 'auth/Api',
        add: 'auth/Api',
        delete: 'auth/Api/',
        actions: 'auth/httpAction'
    }

    static account = {
        auth: 'auth/Account/authenticate',
        navigation: 'auth/account/userNavigation'

    }
}

export default urlPath