import React, { useMemo, useState } from 'react'
import { createContext } from 'react'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../../../../globalSlice'

export const AccountPayableContext = createContext(null)


function AccountPayableContextProvide(props) {

    const [challanShow, setChallanShow] = useState(false)
    const [billInfoShow, setBillInfoShow] = useState(false)
    const [accessoriesShow, setAccessoriesShow] = useState(false)
    const [model, setModel] = useState()
    const dispatch = useDispatch();

    const accountPayableModal = useMemo(() => ({
        openChallanDetails: () => {
            setChallanShow(prev => !prev)
        },
        openBillinfo: () => {
            setBillInfoShow(prev => !prev)
        },
        accessoriesInfo: () => {

            model && model.otherAccessoryList.length > 0 ?
                setAccessoriesShow(prev => !prev) :
                dispatch(setNotification({ type: 'error', message: "Please Select Supplier Code !" }))

        }

    }))


    const value = {
        accountPayableModal,
        challanShow, setChallanShow,
        billInfoShow, setBillInfoShow,
        accessoriesShow, setAccessoriesShow,
        model, setModel,
    }

    return (
        <AccountPayableContext.Provider value={value}>
            {props.children}
        </AccountPayableContext.Provider>
    )
}

export default AccountPayableContextProvide