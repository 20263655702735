import { Box, Button, IconButton, Modal, Typography } from '@mui/material'
import React, { useContext } from 'react'
import CloseIcon from '@mui/icons-material/Close';


function CustomModal(props) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '98%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    return (
        <>
            <Modal
                open={props.state}
                onClose={() => props.setState(!props.state)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ position: 'fixed', top: 5, right: 2 }}>
                        <IconButton aria-label="delete" onClick={() => props.setState(!props.state)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    {props.children}
                </Box>

            </Modal>
        </>
    )
}

export default CustomModal